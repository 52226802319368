import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { RegisterSensorFieldsRequestDTOWithNameField } from "../../../screens/sensors/add-update-sensor";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";

interface IProps {
  fieldList: { [key: string]: any };
  parentName: string;
  selectedFields: { id: number | null; fieldPath: string }[];
  setSelectedFields: React.Dispatch<
    React.SetStateAction<{ id: number | null; fieldPath: string }[]>
  >;
  isHideAlreadySelectedFields: boolean;
  searchKeyword: string;
  fieldsForRegistration: RegisterSensorFieldsRequestDTOWithNameField[];
  expandedList: { fieldPath: string; value: boolean }[];
  setExpandedList: React.Dispatch<
    React.SetStateAction<{ fieldPath: string; value: boolean }[]>
  >;
}

const PlcField = (props: IProps) => {
  const {
    fieldList,
    parentName,
    selectedFields,
    isHideAlreadySelectedFields,
    searchKeyword,
    fieldsForRegistration,
    expandedList,
    setSelectedFields,
    setExpandedList,
  } = props;

  useEffect(() => {
    Object.entries(fieldList)
      .map(([keyString, value], index) => {
        if (typeof value !== "number") {
          return { keyString, value: false };
        } else {
          return undefined;
        }
      })
      .filter((value) => value !== undefined)
      .forEach((value) => {
        const isFound = expandedList.find(
          (el) =>
            el.fieldPath ===
            (parentName ? `${parentName}.${value.keyString}` : value.keyString)
        );

        if (!isFound) {
          setExpandedList((ps) => [
            ...ps,
            {
              fieldPath: parentName
                ? `${parentName}.${value.keyString}`
                : value.keyString,
              value: value.value,
            },
          ]);
        }
      });
  }, [fieldList]);

  return (
    <div>
      {Object.entries(fieldList).map(([keyString, value], index) => {
        const fieldPath = parentName ? `${parentName}.${keyString}` : keyString;
        const includes = selectedFields
          .map((sf) => sf.fieldPath)
          .includes(fieldPath);

        const isExpanded = expandedList.find(
          (ex) => ex.fieldPath === fieldPath
        );

        return (
          <Row key={uuid()} className="mt-2">
            {typeof value === "number" ? (
              (!includes || !isHideAlreadySelectedFields) &&
              (!searchKeyword ||
                keyString
                  .toLowerCase()
                  .includes(searchKeyword.toLowerCase())) && (
                <Col className={`${includes ? "bg-primary" : ""} rounded px-4`}>
                  <Row className="align-items-center">
                    <Col className="col-auto pe-0 ps-1">
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setSelectedFields((ps) => {
                            const fieldPaths = ps.map((tps) => tps.fieldPath);

                            if (
                              !fieldPaths.includes(
                                parentName
                                  ? `${parentName}.${keyString}`
                                  : keyString
                              )
                            ) {
                              return [
                                ...ps,
                                {
                                  id: null,
                                  fieldPath: parentName
                                    ? `${parentName}.${keyString}`
                                    : keyString,
                                },
                              ];
                            }

                            const foundIndex = fieldPaths.findIndex(
                              (field) =>
                                field ===
                                (parentName
                                  ? `${parentName}.${keyString}`
                                  : keyString)
                            );

                            if (
                              fieldsForRegistration.find(
                                (field) =>
                                  field.fieldPath ===
                                  (parentName
                                    ? `${parentName}.${keyString}`
                                    : keyString)
                              )
                            ) {
                              return [...ps];
                            }

                            return ps.filter((field, fi) => fi !== foundIndex);
                          });
                        }}
                      >
                        <MaterialIcon
                          icon={includes ? "check" : "add"}
                          color={includes ? "#2f2a89" : "#fff"}
                          size={10}
                          className={`${
                            includes ? "bg-white" : "bg-primary"
                          } rounded-circle p-1`}
                        />
                      </div>
                    </Col>

                    <Col
                      className={`${includes ? `text-white` : `text-light`}`}
                    >
                      {keyString}
                    </Col>

                    <Col
                      className={`col-auto ${
                        includes ? `text-white` : `text-dark`
                      }`}
                    >
                      {value}
                    </Col>
                  </Row>
                </Col>
              )
            ) : (
              <Col>
                <Row className="flex-column">
                  <Col>
                    <Row className="align-items-center">
                      <Col className="col-auto ps-0">
                        <div className="cursor-pointer">
                          <MaterialIcon
                            icon={
                              isExpanded?.value
                                ? "arrow_drop_down"
                                : "arrow_right"
                            }
                            color="#2f2a89"
                            size={32}
                            onClick={() => {
                              setExpandedList((ps) => {
                                return ps.map((ex) =>
                                  ex.fieldPath === fieldPath
                                    ? { ...ex, value: !ex.value }
                                    : ex
                                );
                              });
                            }}
                          />
                        </div>
                      </Col>

                      <Col className="text-light">{keyString}</Col>
                    </Row>
                  </Col>

                  {isExpanded?.value && (
                    <Col className="ps-5">
                      <PlcField
                        fieldList={value}
                        parentName={fieldPath}
                        selectedFields={selectedFields}
                        isHideAlreadySelectedFields={
                          isHideAlreadySelectedFields
                        }
                        searchKeyword={searchKeyword}
                        fieldsForRegistration={fieldsForRegistration}
                        expandedList={expandedList}
                        setSelectedFields={setSelectedFields}
                        setExpandedList={setExpandedList}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            )}
          </Row>
        );
      })}
    </div>
  );
};

export default PlcField;
