import { Col, Container, Row } from "react-bootstrap";
import device from "../../assets/images/device_thermostat.svg";
import { EColors } from "../../enums/colors";
import { getWidgetTextColor } from "../../utils/getWidgetTextColor";
import styles from "./gauge.module.scss";
import { ReadingUnit } from "../../enums";
import { getReadingTypes } from "../../utils/getReadingTypes";

interface IProps {
  value: number;
  size?: "sm" | "lg";
  textColor?: EColors;
  unit?: ReadingUnit;
}

const Gauge = (props: IProps) => {
  return (
    <div className={`${styles.gauge} ${props.size === "sm" && styles.sm}`}>
      <div className={styles.gauge__body}>
        <div
          className={styles.gauge__fill}
          style={{
            transform: `rotate(${props.value / 200}turn`,
          }}
        ></div>
        <div className={styles.gauge__cover}>
          <Container fluid>
            <Row className={"align-items-center justify-content-center"}>
              <Col xs={"auto"} className={"p-0"}>
                <div className={styles.icon}>
                  <img src={device} alt={""} />
                </div>
              </Col>
              <Col xs={"auto"} className={"p-0"}>
                <label
                  className={styles.value}
                  style={{
                    color: `${getWidgetTextColor(props.textColor as EColors)}`,
                  }}
                >
                  {props.value}
                </label>
                <label className={`${styles.unit} ps-2`}>
                  {props.unit
                    ? getReadingTypes(props.unit as ReadingUnit) || props.unit
                    : "C"}
                </label>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Gauge;
