import { Col, Container, Row } from "react-bootstrap";
import { WidgetType } from "../../../enums";
import { EColors } from "../../../enums/colors";
import Gauge from "../../../ui-elements/gauge/gauge";
import styles from "../counter-widget/counter-widget.module.scss";

interface IProps {
  type: WidgetType;
  textColor: EColors;
}

const GaugeWidget = (props: IProps) => {
  return (
    <Container
      fluid
      className={`${styles.widgetContainer} ${
        props.type === WidgetType.WIDGET_TYPE_02 && styles.selectedWidget
      } p-4`}
    >
      <Row className="justify-content-between">
        <Col className={styles.title}>Steam Bleacher Temperature</Col>
      </Row>
      <Row className="mt-4 mb-2">
        <Col className="d-flex align-items-center justify-content-center">
          <Gauge textColor={props.textColor} value={80} size="sm" />
        </Col>
      </Row>
    </Container>
  );
};

export default GaugeWidget;
