import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  useAddSensorMutation,
  useUpdateSensorMutation,
} from "../../redux/api/sensor-api/sensor-api";
import { useLazyViewTelemetryRecordsQuery } from "../../redux/api/telemetry-api/telemetry-api";
import backICon from "../../shared/assets/images/arrow-back.svg";
import arrowWriteIcon from "../../shared/assets/images/arrow-right.svg";
import PlcField from "../../shared/components/plc-field/plc-field";
import { ReadingUnit } from "../../shared/enums";
import { RegisterSensorFieldsRequestDTO } from "../../shared/interfaces/dtos/request-dtos/add-sensor-request-dto";
import { ParameterDefinition } from "../../shared/interfaces/modals";
import AppSelect, {
  Option,
} from "../../shared/ui-elements/app-select/app-select";
import Button from "../../shared/ui-elements/button/button";
import CheckBox from "../../shared/ui-elements/check-box/check-box";
import IconButton from "../../shared/ui-elements/icon-button/icon-button";
import MaterialIcon from "../../shared/ui-elements/material-icon/material-icon";
import TextField from "../../shared/ui-elements/text-field/text-field";
import { showFailureToast, showSuccessToast } from "../../shared/utils";
import { Pages } from "../routes";
import styles from "./add-sensors.module.scss";

interface Inputs {
  sensorName: string;
  modelNumber: string;
  fields: { label: string; unit: Option }[];
  searchKeyword: string;
}

export interface RegisterSensorFieldsRequestDTOWithNameField
  extends RegisterSensorFieldsRequestDTO {
  name: string;
}

interface ILocationState {
  sensorId: string;
  deviceId: string;
  sensorName: string;
  modelNumber: string;
  parameterDefinitions: ParameterDefinition[];
}

const units = Object.values(ReadingUnit).map((unit) => {
  return { label: unit, value: unit };
});

const AddUpdateSensor = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    control,
    reset,
  } = useForm<Inputs>();

  const navigate = useNavigate();
  const location = useLocation();
  const locationState: ILocationState = location.state as ILocationState;
  const [triggerViewTelemetryRecords] = useLazyViewTelemetryRecordsQuery();
  const [plcs, setPlcs] = useState<Option[]>([]);
  const [fieldsList, setFieldsList] = useState<{ [Key: string]: any }>({});
  const [telemetryRecords, setTelemetryRecords] = useState<{
    [key: string]: any;
  }>();
  const [selectedFields, setSelectedFields] = useState<
    { id: number | null; fieldPath: string }[]
  >([]);
  const [fieldsForRegistration, setFieldsForRegistration] = useState<
    RegisterSensorFieldsRequestDTOWithNameField[]
  >([]);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [addSensor] = useAddSensorMutation();
  const [updateSensor] = useUpdateSensorMutation();
  const [isHideAlreadySelectedFields, setIsHideAlreadySelectedFields] =
    useState(false);
  const [showError, setShowError] = useState(false);
  const [selectedPlc, setSelectedPlc] = useState<Option>({
    label: "",
    value: "",
  });
  const [expandedList, setExpandedList] = useState<
    { fieldPath: string; value: boolean }[]
  >([]);

  const searchKeyword = watch("searchKeyword");

  const { remove } = useFieldArray({ control, name: "fields" });

  useEffect(() => {
    triggerViewTelemetryRecords()
      .unwrap()
      .then((res) => {
        setPlcs(
          Object.keys(res.telemetryRecords).map((key) => {
            return { label: key, value: key };
          })
        );

        if (isFirstTime) {
          setSelectedPlc(
            Object.keys(res.telemetryRecords).map((key) => {
              return { label: key, value: key };
            })[0]
          );

          setIsFirstTime(false);
        }

        setTelemetryRecords(res.telemetryRecords);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [triggerViewTelemetryRecords]);

  useEffect(() => {
    if (telemetryRecords && selectedPlc.value) {
      setFieldsList(telemetryRecords[selectedPlc.value]);
    }

    if (!locationState) {
      setSelectedFields([]);
      setFieldsForRegistration([]);
    }
  }, [selectedPlc, telemetryRecords, locationState]);

  useEffect(() => {
    if (locationState && plcs.length > 0) {
      setValue("sensorName", locationState.sensorName);
      setValue("modelNumber", locationState.modelNumber);

      setSelectedPlc(
        plcs.find((plc) => plc.value === locationState.deviceId) || plcs[0]
      );
      setSelectedFields(
        locationState.parameterDefinitions.map((pd) => {
          return { id: pd.id, fieldPath: pd.fieldPath };
        })
      );

      setFieldsForRegistration(
        locationState.parameterDefinitions.map((pd) => {
          const splitNames = pd.fieldPath.split(".");
          return {
            name: splitNames[splitNames.length - 1],
            label: pd.label,
            fieldPath: pd.fieldPath,
            unit: pd.unit,
            fieldId: pd.id,
          };
        })
      );

      setValue(
        "fields",
        locationState.parameterDefinitions.map((pd) => {
          return {
            label: pd.label,
            unit: units.find((unit) => unit.value === pd.unit) || units[0],
          };
        })
      );
    }
  }, [locationState, plcs]);

  const transferFieldsForRegistration = () => {
    setFieldsForRegistration(
      selectedFields.map((field) => {
        const splitNames = field.fieldPath.split(".");
        return {
          name: splitNames[splitNames.length - 1],
          label: "",
          fieldPath: field.fieldPath,
          unit: ReadingUnit.CELSIUS,
          fieldId: field.id,
        };
      })
    );
  };

  const removeFieldFromRegistration = (fieldPath: string, index: number) => {
    setFieldsForRegistration((ps) =>
      ps.filter((field) => field.fieldPath !== fieldPath)
    );

    remove(index);

    setSelectedFields((ps) =>
      ps.filter((field) => field.fieldPath !== fieldPath)
    );
  };

  useEffect(() => {
    if (fieldsForRegistration.length > 0) {
      setShowError(false);
    }
  }, [fieldsForRegistration]);

  const onSubmit = (data: Inputs) => {
    if (fieldsForRegistration.length === 0) {
      setShowError(true);
      return;
    }

    const fields = data.fields.map((field, index) => {
      return {
        label: field.label,
        unit: field.unit.value as ReadingUnit,
        fieldPath: selectedFields[index].fieldPath,
        fieldId: selectedFields[index].id,
      };
    });

    (locationState
      ? updateSensor({
          sensorId: locationState.sensorId,
          label: data.sensorName,
          modelNumber: data.modelNumber,
          deviceId: selectedPlc.value,
          fields,
        })
      : addSensor({
          label: data.sensorName,
          modelNumber: data.modelNumber,
          deviceId: selectedPlc.value,
          fields,
        })
    )
      .unwrap()
      .then(() => {
        showSuccessToast(
          locationState
            ? "Sensor updated successfully"
            : "sensor added successfully"
        );
        setSelectedFields([]);
        setFieldsForRegistration([]);
        reset();
        navigate(Pages.sensors);
      })
      .catch(() => {
        showFailureToast(
          locationState
            ? "Updating sensor is unsuccessful"
            : "Adding sensor is unsuccessful"
        );
      });
  };

  return (
    <div className={`${styles.container} py-3 px-4`}>
      <Row className="align-items-center">
        <Col className="col-auto pe-0">
          <IconButton
            background="#2F2A89"
            icon={backICon}
            width={44}
            height={44}
            padding="12px"
            borderRadius={17}
            onClick={() => navigate(-1)}
          />
        </Col>
        <Col className={styles.txt01}>
          {locationState ? "Update Sensor" : "Add New Sensor"}
        </Col>
      </Row>
      <Row className={`mt-3 ${styles.sensorDetails} mx-0 py-3 px-2`}>
        <Row>
          <Col className="text-dark font-size-18 font-weight-500">
            Sensor Details
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="col-12 col-sm">
            <TextField
              name="sensorName"
              placeholder="Sensor Name"
              label="Sensor Name"
              register={register("sensorName", {
                required: "Sensor name is required",
              })}
              errors={errors}
            />
          </Col>
          <Col className="mt-2 mt-sm-0">
            <TextField
              name="modelNumber"
              placeholder="Model Number"
              label="Model Number"
              register={register("modelNumber", {
                required: "Model number is required",
              })}
              errors={errors}
            />
          </Col>
        </Row>
      </Row>
      <Row className="align-items-center mt-3 mx-0">
        <Col className={`p-3 ${styles.sensorDetails}`}>
          <Row className="align-items-center">
            <Col className="text-dark font-size-18 font-weight-500">
              Available Fields
            </Col>
            <Col>
              <Row className="align-items-center">
                <Col className="col-auto pe-0">PLC</Col>
                <Col>
                  <AppSelect
                    options={plcs}
                    selectedValue={selectedPlc}
                    onChangeOption={(selectedOption) => {
                      setExpandedList([]);
                      setSelectedPlc(selectedOption);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="align-items-center mt-2">
            <Col>
              <TextField
                name="searchKeyword"
                placeholder="Search Fields"
                register={register("searchKeyword")}
                errors={errors}
              />
            </Col>
            <Col>
              <Row className="align-items-center">
                <Col className="col-auto pe-0">
                  <CheckBox
                    checked={isHideAlreadySelectedFields}
                    onChange={(checked) => {
                      setIsHideAlreadySelectedFields(checked);
                    }}
                  />
                </Col>
                <Col className={`${styles.txt02} ps-0`}>
                  Hide already selected fields
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <PlcField
                fieldList={fieldsList}
                parentName=""
                selectedFields={selectedFields}
                setSelectedFields={setSelectedFields}
                isHideAlreadySelectedFields={isHideAlreadySelectedFields}
                searchKeyword={searchKeyword}
                fieldsForRegistration={fieldsForRegistration}
                expandedList={expandedList}
                setExpandedList={setExpandedList}
              />
            </Col>
          </Row>
        </Col>
        <Col className="col-auto">
          <IconButton
            background="#2F2A89"
            icon={arrowWriteIcon}
            width={35}
            height={110}
            padding="11px"
            borderRadius={8}
            onClick={transferFieldsForRegistration}
          />
        </Col>
        <Col className={`p-3 ${styles.sensorDetails}`}>
          <Row>
            <Col className="text-dark font-size-18 font-weight-500">
              Selected Fields
            </Col>
          </Row>
          {fieldsForRegistration.map((field, index) => {
            return (
              <Row
                key={uuid()}
                className={`${styles.selectedFieldContainer} ${
                  index === 0 ? `mt-2` : `mt-3`
                } mx-0 py-3 px-1`}
              >
                <Col>
                  <Row>
                    <Col className={styles.text03}>{field.name}</Col>
                    <Col className="col-auto">
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          removeFieldFromRegistration(field.fieldPath, index);
                        }}
                      >
                        <MaterialIcon
                          icon="delete"
                          className="bg-primary text-white rounded p-2"
                          size={16}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <TextField
                        placeholder="Label"
                        label="Custom Label"
                        register={register(`fields.${index}.label`, {
                          required: "Custom label is required",
                        })}
                        errorMessage={
                          errors.fields
                            ? errors.fields[index]?.label?.message
                            : ""
                        }
                        errors={errors}
                      />
                    </Col>
                    <Col>
                      <AppSelect
                        defaultValue={""}
                        placeholder="Unit"
                        options={[...units]}
                        label="Unit"
                        control={control}
                        name={`fields.${index}.unit`}
                        register={register(`fields.${index}.unit`, {
                          required: "Please select a range",
                        })}
                        errorMessage={
                          errors.fields
                            ? errors.fields[index]?.unit?.message
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          })}
          <Row className="mt-4 justify-content-end">
            <Col xs="auto">
              <Button
                text={
                  locationState ? "Save & Update Sensor" : "Save & Add Sensor"
                }
                onClick={handleSubmit(onSubmit)}
                variant="Primary"
                borderRadius={24}
                padding="12px 30px"
                type="button"
              />
            </Col>
          </Row>
        </Col>
        <Row className="p-3 mx-0">
          <Col className="text-danger">
            {showError && "Please select atleast one field"}
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default AddUpdateSensor;
