import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import { useViewDashboardsQuery } from "../../../redux/api/dashboard-api/dashboard-api";
import iconEdit from "../../assets/images/edit.svg";
import stacks from "../../assets/images/stacks-white.svg";
import { Dashboard } from "../../interfaces/modals";
import Button from "../../ui-elements/button/button";
import IconButton from "../../ui-elements/icon-button/icon-button";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
import styles from "./dashboard-side-menu.module.scss";

type MenuContextType = [boolean, React.Dispatch<React.SetStateAction<boolean>>];

const defaultDashboardValue: Dashboard = {
  id: "",
  label: "",
  widgets: [],
};

interface IProps {
  onShowAddUpdateDashboard: () => void;
  onHidedUpdateDashboard: () => void;
  setShowSideMenuState: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedDashboard: React.Dispatch<React.SetStateAction<Dashboard>>;
  selectedDashboard: Dashboard;
}

const DashboardSideMenu = (props: IProps) => {
  const {
    setShowSideMenuState,
    onShowAddUpdateDashboard,
    onHidedUpdateDashboard,
    setSelectedDashboard,
    selectedDashboard,
  } = props;

  const { data: dashboards } = useViewDashboardsQuery();
  const [showSideMenu, setShowSideMenu] =
    useOutletContext<MenuContextType>() ?? [false, () => {}];

  useEffect(() => {
    setShowSideMenuState(showSideMenu);
  }, [showSideMenu, setShowSideMenuState]);

  useEffect(() => {
    if (dashboards) {
      setSelectedDashboard(dashboards?.dashboards.map((w) => w)[0]);
    }
  }, [dashboards]);

  return (
    <Container
      fluid
      className={`${styles.workflowSideMenuContainer} ${
        showSideMenu ? styles.show : styles.hide
      } me-0`}
    >
      <Row className={"align-items-center justify-content-between"}>
        <Col xs={"auto"}>
          <IconButton
            background="#2F2A89"
            icon={stacks}
            width={50}
            height={50}
            padding="10px"
            borderRadius={17}
          />
        </Col>
        <Col>
          <div className={styles.title}>Dashboards</div>
        </Col>
        <Col xs="auto">
          <MaterialIcon
            icon="close"
            className="cursor-pointer"
            size={25}
            onClick={() => {
              setShowSideMenu(false);
            }}
          />
        </Col>
      </Row>
      <Row className="mt-4 ps-2">
        {dashboards?.dashboards.map((w) => (
          <Col
            key={w.id}
            xs={12}
            className={`${styles.item} ${
              selectedDashboard === w ? styles.selected : ""
            }`}
            onClick={() => {
              setSelectedDashboard(w);
              onHidedUpdateDashboard();
            }}
          >
            <Row className="align-items-center">
              <Col
                className={`${styles.workflowName} ${
                  selectedDashboard === w ? styles.selected : ``
                }`}
              >
                {w.label}
              </Col>
              <Col xs="auto">
                <img
                  src={iconEdit}
                  alt={""}
                  onClick={(event) => {
                    event.stopPropagation();
                    setSelectedDashboard(w);
                    onShowAddUpdateDashboard();
                  }}
                  className="cursor-pointer"
                />
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
      <Row className="mt-4 justify-content-center">
        <Col xs="auto">
          <Button
            text="New Dashboard"
            onClick={() => {
              onShowAddUpdateDashboard();
              setSelectedDashboard({ ...defaultDashboardValue });
            }}
            variant="Primary"
            borderRadius={24}
            type="button"
            padding="12px 70px"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardSideMenu;
