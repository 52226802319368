import { Col, Container, Row } from "react-bootstrap";
import arrow from "../../../assets/images/arrow.svg";
import dropDown from "../../../assets/images/arrow_drop_down.svg";
import { EColors } from "../../../enums/colors";
import Gauge from "../../../ui-elements/gauge/gauge";
import IconButton from "../../../ui-elements/icon-button/icon-button";
import styles from "../amount/widget-amount.module.scss";
import { ReadingUnit } from "../../../enums";

interface IProps {
  title: string;
  value: number;
  unit: string;
  onClick: () => void;
  textColor: EColors;
}

const WidgetTemperature = (props: IProps) => {
  return (
    <Container fluid className={`${styles.widgetContainer} p-4`}>
      <Row className="justify-content-between">
        <Col xs="auto">
          <div className={styles.title}>{props.title}</div>
          <Row className={`align-items-center ${styles.subTitle}`}>
            <Col xs={"auto"} className={"pe-0"}>
              Today
            </Col>
            <Col xs={"auto"} className={"ps-2"}>
              <img src={dropDown} alt={""} />
            </Col>
          </Row>
        </Col>
        <Col xs={"auto"}>
          <IconButton
            icon={arrow}
            width={60}
            height={40}
            onClick={() => {
              props.onClick();
            }}
            background="#2F2A89"
            boxShadow="-4px 10px 22px 0px rgba(47, 42, 137, 0.42)"
            padding="8px 20px"
            borderRadius={16}
          />
        </Col>
      </Row>
      <Row className="mt-4 mb-2">
        <Col className="d-flex align-items-center justify-content-center">
          <Gauge
            value={props.value}
            textColor={props.textColor}
            unit={props.unit as ReadingUnit}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default WidgetTemperature;
