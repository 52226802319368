import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  useDeleteTriggerMutation,
  useLazyViewExecutedTriggersQuery,
  useViewTriggersQuery,
} from "../../redux/api/trigger-api/trigger-api";
import TriggerSideMenu from "../../shared/components/alert-side-menu/trigger-side-menu";
import ExectutedTriggerCard from "../../shared/components/exectuted-trigger-card/exectuted-trigger-card";
import Pagination from "../../shared/components/pagination/pagination";
import Tabs from "../../shared/components/tabs/tabs";
import TriggerCard from "../../shared/components/trigger-card/trigger-card";
import TriggerDeleteModal from "../../shared/components/trigger-delete-modal/trigger-delete-modal";
import { ExecutedTrigger, Trigger } from "../../shared/interfaces/modals";
import Button from "../../shared/ui-elements/button/button";
import { showFailureToast, showSuccessToast } from "../../shared/utils";
import { Pages } from "../routes";
import styles from "./manage-triggers.module.scss";

const ITEMS_PER_PAGE = 10;

const ManageTriggers = () => {
  const navigate = useNavigate();

  const [showSideMenuState, setShowSideMenuState] = useState(true);
  const [selectedId, setSelectedId] = useState(1);
  const [isShowTriggerDeleteModal, setIsShowTriggerDeleteModal] =
    useState(false);
  const [triggerDeleteId, setTriggerDeleteId] = useState("0");
  const [deleteTrigger, { isLoading: isDeletingTrigger }] =
    useDeleteTriggerMutation();
  const [triggersLength, setTriggersLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [executedTriggersLength, setExecutedTriggersLength] = useState(0);
  const [currentExecutedPage, setCurrentExecutedPage] = useState(0);
  const [executedTriggers, setExecutedTriggers] = useState<ExecutedTrigger[]>(
    []
  );

  const { data: triggers } = useViewTriggersQuery({
    page: currentPage,
    size: ITEMS_PER_PAGE,
  });
  const [executedTriggerGetTriggers] = useLazyViewExecutedTriggersQuery();

  const onShowTriggerDeleteModal = (triggerId: string) => {
    setTriggerDeleteId(triggerId);
    setIsShowTriggerDeleteModal(true);
  };

  useEffect(() => {
    const fetchExecutedTriggers = () => {
      executedTriggerGetTriggers({
        page: currentExecutedPage,
        size: ITEMS_PER_PAGE,
      })
        .unwrap()
        .then((res) => {
          setExecutedTriggers(res.triggers);
          setExecutedTriggersLength(res.totalElements);
        })
        .catch(() => {
          setExecutedTriggers([]);
          setExecutedTriggersLength(0);
        });
    };

    fetchExecutedTriggers();

    const intervalId = setInterval(fetchExecutedTriggers, 3000);

    return () => clearInterval(intervalId);
  }, [currentExecutedPage, executedTriggerGetTriggers]);

  const onDelete = async () => {
    setIsShowTriggerDeleteModal(false);

    if (triggerDeleteId) {
      await deleteTrigger(triggerDeleteId)
        .unwrap()
        .then(() => {
          showSuccessToast("Trigger deleted successfully");
        })
        .catch(() => {
          showFailureToast("Failed to delete the trigger");
        });
    }
  };

  const onEdit = (trigger: Trigger) => {
    navigate(Pages.updateAlert, {
      state: {
        id: trigger.id,
        label: trigger.label,
        deviceId: trigger.deviceId,
        durationInMinutes: trigger.durationInMinutes,
        recipientIds: trigger.triggerActions[0].additionalFields.recipientIds,
        action: trigger.triggerActions[0].type,
        rules: trigger.triggerRules.map((rule) => {
          return {
            sensorParamDefId: rule.sensorParamDefId,
            condition: rule.condition,
            value: rule.value,
          };
        }),
      },
    });
  };

  return (
    <>
      <Row className="align-items-center justify-content-between">
        <Col xs={12} md={9}>
          <Tabs />
        </Col>
        <Col xs={12} md={3} xl={2} className="mt-3 mt-md-0">
          <Button
            text="New Trigger"
            onClick={() => navigate(Pages.addAlert)}
            variant="Primary"
            borderRadius={24}
            type="button"
          />
        </Col>
      </Row>
      <div className={`${styles.alertContainer} mt-4 d-block d-md-flex`}>
        <div className={`${styles.menu} ${!showSideMenuState && styles.hide}`}>
          <TriggerSideMenu
            setShowSideMenuState={setShowSideMenuState}
            selectedId={selectedId}
            onClick={(id) => setSelectedId(id)}
            items={[
              { id: 1, itemName: "Executed Triggers" },
              { id: 2, itemName: "Manage Triggers" },
            ]}
          />
        </div>
        <div className={`${styles.content} ${showSideMenuState && `ms-3`} p-4`}>
          <Row>
            <Col className={styles.title}>
              {selectedId === 1 ? (
                <>
                  <div>Executed Triggers</div>
                  <Row className="mt-4">
                    {executedTriggers?.map((a) => (
                      <Col xs={12} className="mb-3" key={uuid()}>
                        <ExectutedTriggerCard
                          executedTrigger={a}
                          onDelete={() => {}}
                        />
                      </Col>
                    ))}
                  </Row>
                  <Row>
                    <Col>
                      <Pagination
                        length={executedTriggersLength}
                        itemsPerPage={ITEMS_PER_PAGE}
                        currentPage={currentExecutedPage + 1}
                        updateCurrentPage={(executedPageNumber: number) => {
                          setCurrentExecutedPage(executedPageNumber - 1);
                        }}
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <div>Manage Triggers</div>
                  <Row className="mt-4">
                    {triggers &&
                      triggers.triggers.map((a) => (
                        <Col xs={12} className="mb-3" key={uuid()}>
                          <TriggerCard
                            trigger={a}
                            onDelete={onShowTriggerDeleteModal}
                            onEdit={onEdit}
                          />
                        </Col>
                      ))}
                  </Row>
                  <Row>
                    <Col>
                      <Pagination
                        length={triggersLength}
                        itemsPerPage={ITEMS_PER_PAGE}
                        currentPage={currentPage + 1}
                        updateCurrentPage={(pageNumber: number) => {
                          setCurrentPage(pageNumber - 1);
                        }}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <TriggerDeleteModal
        show={isShowTriggerDeleteModal}
        onClose={() => {
          setIsShowTriggerDeleteModal(false);
        }}
        onConfirm={onDelete}
      />
    </>
  );
};

export default ManageTriggers;
