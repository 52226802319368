import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLazyViewAllUsersQuery } from "../../redux/api/user-api/user-api";
import DataTable from "../../shared/components/data-table/data-table";
import Pagination from "../../shared/components/pagination/pagination";
import SettingSideMenu from "../../shared/components/setting-side-menu/setting-side-menu";
import Tabs from "../../shared/components/tabs/tabs";
import { EContactTypes } from "../../shared/enums";
import IViewAllUsersResponseDTO from "../../shared/interfaces/dtos/response-dtos/view-all-users-response-dto";
import IViewUserResponseDTO from "../../shared/interfaces/dtos/response-dtos/view-user-response-dto";
import Button from "../../shared/ui-elements/button/button";
import SpinnerModal from "../../shared/ui-elements/spinner/spinner";
import { capitalizeFirstLetter } from "../../shared/utils";
import { Pages } from "../routes";
import styles from "./settings.module.scss";

const headers = [
  {
    id: 1,
    columnName: "Username",
  },
  {
    id: 2,
    columnName: "First name",
  },
  {
    id: 3,
    columnName: "Last Name",
  },
  {
    id: 4,
    columnName: "Mobile Number",
  },
  {
    id: 5,
    columnName: "Email",
  },
  {
    id: 6,
    columnName: "Role",
  },
  {
    id: 7,
    columnName: "Action",
    isTextEnd: true,
  },
];

const ITEMS_PER_PAGE = 10;

const Settings = () => {
  const navigate = useNavigate();

  const [showSideMenuState, setShowSideMenuState] = useState(true);
  const [selectedId, setSelectedId] = useState(1);
  const [users, setUsers] = useState<IViewUserResponseDTO[]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  const [allUsers, { isFetching }] = useLazyViewAllUsersQuery();

  useEffect(() => {
    allUsers({ page: currentPage, size: ITEMS_PER_PAGE })
      .unwrap()
      .then((res: IViewAllUsersResponseDTO) => {
        setUsers(res.users);
      })
      .catch(() => {
        setUsers([]);
      });
  }, [allUsers, currentPage]);

  return (
    <>
      <Row className="align-items-center">
        <Col xs={12} md={9}>
          <Tabs />
        </Col>
      </Row>
      <Row className="mt-3 justify-content-end">
        <Col xs={12} md={3} xl={2}>
          <Button
            text="New User"
            onClick={() => {
              navigate(Pages.addUser);
            }}
            variant="Primary"
            borderRadius={24}
            type="button"
          />
        </Col>
      </Row>
      <div className={`${styles.settingsContainer} mt-4 d-block d-md-flex`}>
        <div className={`${styles.menu} ${!showSideMenuState && styles.hide}`}>
          <SettingSideMenu
            setShowSideMenuState={setShowSideMenuState}
            selectedId={selectedId}
            onClick={(id) => setSelectedId(id)}
            items={[{ id: 1, itemName: "Manage Users" }]}
          />
        </div>
        <div
          className={`${styles.content} ${
            showSideMenuState && `ms-0 ms-md-3`
          } p-4 position-relative mt-3 mt-md-0`}
        >
          {users.length > 0 ? (
            <>
              {" "}
              <Row>
                <Col>
                  <DataTable
                    headers={headers}
                    data={users?.map((u) => ({
                      data: [
                        <>{u.username}</>,
                        <>{u.firstName}</>,
                        <>{u.lastName}</>,
                        <>
                          {
                            u.contacts?.filter(
                              (c) =>
                                c.contactType === EContactTypes.MOBILE_NUMBER
                            )[0]?.contact
                          }
                        </>,
                        <>
                          {
                            u.contacts?.filter(
                              (c) => c.contactType === EContactTypes.EMAIL
                            )[0]?.contact
                          }
                        </>,
                        <>{capitalizeFirstLetter(u.role)}</>,
                        <>
                          <Row className="justify-content-end">
                            <Col className="col-auto text-primary cursor-pointer">
                              Edit
                            </Col>
                          </Row>
                        </>,
                      ],
                    }))}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Pagination
                    length={1}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage + 1}
                    updateCurrentPage={(pageNumber: number) => {
                      setCurrentPage(pageNumber - 1);
                    }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <div className="container-dash text-center text-light font-size-14">
              No added users
            </div>
          )}
          <SpinnerModal show={isFetching} />
        </div>
      </div>
    </>
  );
};

export default Settings;
