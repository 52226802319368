import { Col, Container, Row } from "react-bootstrap";
import AppModal from "../../../../shared/ui-elements/app-modal/app-modal";
import Button from "../../../../shared/ui-elements/button/button";
import styles from "./widget-delete-confirm-modal.module.scss";

interface IProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}

const WidgetDeleteConfirmModal = (props: IProps) => {
  return (
    <AppModal
      show={props.show}
      onClose={() => {
        props.onClose();
      }}
      width={499}
    >
      <Container>
        <Row className="justify-content-center">
          <Col xs="auto" className={styles.title}>
            Delete
          </Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <Col xs="auto" className={styles.subTitle}>
            Are you sure you want to delete this widget?
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col xs="auto">
            <Button
              type="button"
              text="No"
              variant="Primary"
              borderRadius={24}
              padding="12px 60px"
              onClick={props.onClose}
            />
          </Col>
          <Col xs="auto">
            <Button
              type="button"
              text="Yes"
              variant="Danger"
              borderRadius={24}
              padding="12px 60px"
              onClick={props.onConfirm}
              isLoading={props.isLoading}
            />
          </Col>
        </Row>
      </Container>
    </AppModal>
  );
};

export default WidgetDeleteConfirmModal;
