import { useEffect, useState } from "react";
import { formatNumberWithCommas } from ".";
import { webSocketActions } from "../../redux/actions/webSocketActions";
import { unsubscribe } from "../../redux/api/websocket-middleware";
import { useAppDispatch, useAppSelector } from "../../redux/store/store";
import oilBarrel from "../assets/images/oil_barrel.svg";
import package_2 from "../assets/images/package_2.svg";
import WidgetAmount from "../components/widgets/amount/widget-amount";
import WidgetTank from "../components/widgets/tank/widget-tank";
import WidgetTemperature from "../components/widgets/temperature/widget-temperature";
import { WidgetType } from "../enums";
import { EColors } from "../enums/colors";
import { Widget } from "../interfaces/modals";

interface IProps {
  widgetType: WidgetType;
  widget?: Widget;
  onClick?: (selectedWidget: Widget, showMoreInfo: boolean) => void;
}

const WidgetTypes = (props: IProps) => {
  const dispatch = useAppDispatch();

  const [sensorReadings, setSensorReadings] = useState<number>(0);
  const widgetId = props.widget?.id.toString() ?? "";

  const widgetData = useAppSelector((state) => state.widgetData[widgetId]);

  useEffect(() => {
    if (props.widget) {
      dispatch(webSocketActions({ widgetId }));

      if (widgetData) {
        setSensorReadings(widgetData.dataSources[0].value);
      }

      return () => {
        dispatch(unsubscribe);
      };
    }
  }, [dispatch, widgetId, widgetData, props.widget]);

  switch (props.widgetType) {
    case WidgetType.WIDGET_TYPE_01:
      return (
        <WidgetAmount
          title={props.widget?.label ?? ""}
          unit={props.widget?.dataSources[0].viewingUnit ?? ""}
          icon={package_2}
          value={formatNumberWithCommas(sensorReadings.toFixed(2))}
          onClick={() => {
            if (props.widget && props.onClick) {
              props.onClick(props.widget, true);
            }
          }}
          textColor={
            props.widget?.additional_data?.color?.toUpperCase() as EColors
          }
        />
      );
    case WidgetType.WIDGET_TYPE_02:
      return (
        <WidgetTemperature
          title={props.widget?.label ?? ""}
          value={formatNumberWithCommas(sensorReadings.toFixed(2))}
          unit={props.widget?.dataSources[0].viewingUnit ?? ""}
          onClick={() => {
            if (props.widget && props.onClick) {
              props.onClick(props.widget, true);
            }
          }}
          textColor={
            props.widget?.additional_data?.color?.toUpperCase() as EColors
          }
        />
      );
    case WidgetType.WIDGET_TYPE_03:
      return (
        <WidgetTank
          title={props.widget?.label ?? ""}
          icon={oilBarrel}
          value={formatNumberWithCommas(sensorReadings.toFixed(2))}
          unit={props.widget?.dataSources[0]?.viewingUnit ?? ""}
          upload={formatNumberWithCommas(
            props.widget?.dataSources[0].additionalData?.maxValue ?? 0
          )}
          download={formatNumberWithCommas(
            props.widget?.dataSources[0].additionalData?.minValue ?? 0
          )}
          onClick={() => {
            if (props.widget && props.onClick) {
              props.onClick(props.widget, true);
            }
          }}
          textColor={
            props.widget?.additional_data?.color?.toUpperCase() as EColors
          }
        />
      );
    default:
      return null;
  }
};

export default WidgetTypes;
