import { Col, Container, Row } from "react-bootstrap";
import iconSensor from "../../assets/images/sensor-active.svg";
import { Sensor } from "../../interfaces/modals";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
import styles from "./sensor-card.module.scss";

interface CardProps {
  sensor: Sensor;
  onEdit: (sensor: Sensor) => void;
  onDelete: (sensorId: number) => void;
}

const SensorCard = (props: CardProps) => {
  const { sensor, onEdit, onDelete } = props;

  return (
    <Container fluid className={styles.cardContainer}>
      <Row className="row-cols-auto align-items-center justify-content-between">
        <Col style={{ flex: 1 }}>
          <Row>
            <Col xs={"auto"}>
              <img src={iconSensor} alt={""} />
            </Col>
            <Col className={styles.text01}>{props.sensor.label}</Col>
            <Col className={styles.text02}>{props.sensor.modelNumber}</Col>
          </Row>
        </Col>
        <Col xs={12} md={"auto"} className={"pt-2 pt-md-0"}>
          <Row className={"justify-content-end"}>
            <Col xs={"auto"}>
              <div
                className="cursor-pointer"
                onClick={() => {
                  onEdit(sensor);
                }}
              >
                <MaterialIcon icon="edit_square" className="text-light" />
              </div>
            </Col>
            <Col xs={"auto"}>
              <div
                className="cursor-pointer"
                onClick={() => {
                  onDelete(sensor.id);
                }}
              >
                <MaterialIcon icon="delete" className="text-danger" />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SensorCard;
