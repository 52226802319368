export const validateInputTypeNumberRegex = /^\d*\.?\d*$/;

export const passwordRegexSixToTwenty =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+~`\-={}[\]\\|:;"'<>,.?/])[A-Za-z\d!@#$%^&*()_+~`\-={}[\]\\|:;"'<>,.?/]{6,20}$/;

export const passwordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+~`\-={}[\]\\|:;"'<>,.?/])[A-Za-z\d!@#$%^&*()_+~`\-={}[\]\\|:;"'<>,.?/]{6,}$/;

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const mobileNumberRegex =
  /^[0][7][0 || 1 || 2 || 4 || 5 || 6 || 7 || 8][0-9]{7}$/;

export const noSpecialCharsNotAllowOnlyNumbers =
  /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z])[\w\s]+$/;

export const nicRegex = /^([0-9]{9}[xXvV]|[0-9]{12})$/;
