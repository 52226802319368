import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLazyViewSensorsQuery } from "../../../../redux/api/sensor-api/sensor-api";
import {
  useAddWidgetMutation,
  useUpdateWidgetMutation,
} from "../../../../redux/api/widget-api/widget-api";
import backICon from "../../../../shared/assets/images/arrow-back.svg";
import Colors from "../../../../shared/components/colors/colors";
import CounterWidget from "../../../../shared/components/widget-templates/counter-widget/counter-widget";
import GaugeWidget from "../../../../shared/components/widget-templates/gauge-widget/gauge-widget";
import LevelWidget from "../../../../shared/components/widget-templates/level-widget/level-widget";
import {
  EPower,
  EPressure,
  ETemperature,
  EVoltage,
  EVolume,
  EWeight,
  UnitCategory,
  WidgetType,
} from "../../../../shared/enums";
import { EColors } from "../../../../shared/enums/colors";
import ViewSensorsResponseDTO from "../../../../shared/interfaces/dtos/response-dtos/view-sensors-response-dto";
import {
  ParameterDefinition,
  Widget,
} from "../../../../shared/interfaces/modals";
import AppSelect, {
  Option,
} from "../../../../shared/ui-elements/app-select/app-select";
import Button from "../../../../shared/ui-elements/button/button";
import IconButton from "../../../../shared/ui-elements/icon-button/icon-button";
import MaterialIcon from "../../../../shared/ui-elements/material-icon/material-icon";
import TextField from "../../../../shared/ui-elements/text-field/text-field";
import {
  capitalizeFirstLetter,
  enumToObject,
  showFailureToast,
  showSuccessToast,
} from "../../../../shared/utils";
import WidgetTemplates from "../../../../shared/utils/getWidgetTemplates";
import styles from "./add-update-widget.module.scss";

type Inputs = {
  label: string;
  type: WidgetType;
  sensorParamDefId: number;
  color?: string;
  maxValue?: number;
  minValue?: number;
  optimalValue?: number;
  stepSize?: number;
};

interface IProps {
  onCancel: () => void;
  onClose?: () => void;
  selectedDashboardId: number;
  selectedWidget?: Widget;
}

const emptyOption = {
  value: "",
  label: "",
};

const AddUpdateWidget = (props: IProps) => {
  const [selectedTextColor, setSelectedTextColor] = useState<EColors>();
  const [selectedSensor, setSelectedSensor] = useState<Option>({
    ...emptyOption,
  });
  const [selectedField, setSelectedField] = useState<Option>({
    ...emptyOption,
  });
  const [selectedUnitCategory, setSelectedUnitCategory] =
    useState<UnitCategory>(UnitCategory.POWER);
  const [sensorList, setSensorList] = useState<Option[]>([]);
  const [fieldList, setFieldList] = useState<Option[]>([]);
  const [selectedWidgetType, setSelectedWidgetType] = useState<WidgetType>(
    WidgetType.WIDGET_TYPE_01
  );
  const [selectedViewingUnit, setSelectedViewingUnit] = useState<Option>({
    ...emptyOption,
  });
  const [viewingUnitList, setViewingUnitList] = useState<Option[]>([]);

  const [triggerGetSensors] = useLazyViewSensorsQuery();
  const [addWidget] = useAddWidgetMutation();
  const [updateWidget] = useUpdateWidgetMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<Inputs>();

  useEffect(() => {
    triggerGetSensors({})
      .unwrap()
      .then((res: ViewSensorsResponseDTO) => {
        const sensors = res.sensors.map((s) => {
          return {
            value: s.id.toString(),
            label: s.label,
            data: s,
          };
        });
        setSensorList(sensors);
        setSelectedSensor(sensors[0]);
      })
      .catch(() => {
        console.log("Failed to fetch Sensors");
      });
  }, [triggerGetSensors]);

  useEffect(() => {
    if (selectedSensor.data) {
      const fieldList = selectedSensor.data.parameterDefinitions.map(
        (pd: ParameterDefinition) => {
          return {
            label: pd.label,
            value: String(pd.id),
            data: pd,
          };
        }
      );
      setFieldList(fieldList);
      setSelectedField(fieldList[0]);
    }
  }, [selectedSensor]);

  useEffect(() => {
    if (selectedField.data) {
      setSelectedUnitCategory(selectedField.data.unitCategory);
    }
  }, [selectedField]);

  useEffect(() => {
    if (props.selectedWidget) {
      setSelectedWidgetType(props.selectedWidget.type);
      setSelectedTextColor(
        props.selectedWidget.additional_data?.color?.toUpperCase() as EColors
      );
      setValue("label", props.selectedWidget.label);
      setValue(
        "maxValue",
        props.selectedWidget.dataSources[0].additionalData?.maxValue
      );
      setValue(
        "minValue",
        props.selectedWidget.dataSources[0].additionalData?.minValue
      );
      setValue(
        "optimalValue",
        props.selectedWidget.dataSources[0].additionalData?.optimalValue
      );
      setValue(
        "stepSize",
        props.selectedWidget.dataSources[0].additionalData?.stepSize
      );

      const findSensor = sensorList.filter((s) =>
        s.data.parameterDefinitions.find(
          (pd: ParameterDefinition) =>
            pd.id ===
            props.selectedWidget?.dataSources[0].SensorParamDefinitionId
        )
      );

      if (findSensor.length > 0) {
        const fieldList = findSensor[0].data.parameterDefinitions.map(
          (pd: ParameterDefinition) => {
            return {
              label: pd.label,
              value: String(pd.id),
              data: pd,
            };
          }
        );

        setSelectedSensor({
          value: findSensor[0].value,
          label: findSensor[0].label,
        });

        if (fieldList.length > 0) {
          setFieldList(fieldList);
          setSelectedField(fieldList[0]);
        }
      }
    }
  }, [props.selectedWidget, setValue, sensorList]);

  useEffect(() => {
    switch (selectedUnitCategory) {
      case UnitCategory.TEMPERATURE:
        const list01 = enumToObject(ETemperature, (s) => ({
          value: s,
          label: capitalizeFirstLetter(s),
        }));
        setViewingUnitList(list01);
        setSelectedViewingUnit(list01[0]);
        break;
      case UnitCategory.WEIGHT:
        const list02 = enumToObject(EWeight, (s) => ({
          value: s,
          label: capitalizeFirstLetter(s),
        }));
        setViewingUnitList(list02);
        setSelectedViewingUnit(list02[0]);
        break;
      case UnitCategory.PRESSURE:
        const list03 = enumToObject(EPressure, (s) => ({
          value: s,
          label: capitalizeFirstLetter(s),
        }));
        setViewingUnitList(list03);
        setSelectedViewingUnit(list03[0]);
        break;
      case UnitCategory.VOLUME:
        const list04 = enumToObject(EVolume, (s) => ({
          value: s,
          label: capitalizeFirstLetter(s),
        }));
        setViewingUnitList(list04);
        setSelectedViewingUnit(list04[0]);
        break;
      case UnitCategory.VOLTAGE:
        const list05 = enumToObject(EVoltage, (s) => ({
          value: s,
          label: capitalizeFirstLetter(s),
        }));
        setViewingUnitList(list05);
        setSelectedViewingUnit(list05[0]);
        break;
      default:
        const list = enumToObject(EPower, (s) => ({
          value: s,
          label: capitalizeFirstLetter(s),
        }));
        setViewingUnitList(list);
        setSelectedViewingUnit(list[0]);
    }
  }, [selectedUnitCategory]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (props.selectedWidget?.id) {
      await updateWidget({
        widgetId: props.selectedWidget.id,
        label: data.label,
        type: selectedWidgetType,
        additionalData: {
          color: selectedTextColor,
        },
        dataSources: [
          {
            sensorParamDefId: Number(selectedField.value),
            viewingUnit: selectedViewingUnit.value,
            additionalData: {
              maxValue: data.maxValue,
              minValue: data.minValue,
              optimalValue: data.optimalValue,
              stepSize: data.stepSize,
            },
          },
        ],
      })
        .then(() => {
          showSuccessToast("Widget updated successfully");
          props.onCancel();
          props.onClose && props.onClose();
        })
        .catch(() => {
          showFailureToast("Widget updated unsuccessfully");
        });
    } else {
      await addWidget({
        dashboardId: props.selectedDashboardId,
        label: data.label,
        type: selectedWidgetType,
        additionalData: {
          color: selectedTextColor,
        },
        dataSources: [
          {
            sensorParamDefId: Number(selectedField.value),
            viewingUnit: selectedViewingUnit.value,
            additionalData: {
              maxValue: data.maxValue,
              minValue: data.minValue,
              optimalValue: data.optimalValue,
              stepSize: data.stepSize,
            },
          },
        ],
      })
        .then(() => {
          showSuccessToast("Save Widget successfully");
          reset();
          props.onCancel();
          props.onClose && props.onClose();
        })
        .catch(() => {
          showFailureToast("Failed to save the Widget");
          reset();
        });
    }
  };

  return (
    <>
      <Row className="align-items-center">
        <Col className="col-auto pe-0">
          <IconButton
            background="#2F2A89"
            icon={backICon}
            width={44}
            height={44}
            padding="12px"
            borderRadius={17}
            onClick={props.onCancel}
          />
        </Col>
        <Col className={styles.txt01}>
          {props.selectedWidget ? `Edit` : `Add`} Widget
        </Col>
      </Row>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`mt-4 ${styles.border} p-3`}>
          <Row>
            <Col className="text-dark font-size-18 font-weight-500">
              Data Source
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="col-12 col-sm">
              <AppSelect
                label="Sensor"
                selectedValue={selectedSensor}
                options={sensorList}
                onChangeOption={(selectOption) => {
                  setSelectedSensor(selectOption);
                }}
              />
            </Col>
            <Col className="mt-2 mt-sm-0">
              <AppSelect
                label="Field"
                selectedValue={selectedField}
                options={fieldList}
                onChangeOption={(selectOption) => {
                  setSelectedField(selectOption);
                }}
              />
            </Col>
          </Row>
        </div>
        <div className={`mt-4 ${styles.border} p-3`}>
          <Row>
            <Col className="text-dark font-size-18 font-weight-500">
              Widget Configuration
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="text-dark font-size-16 font-weight-400">
              Widget Type
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <WidgetTemplates
                widgetTemplates={[
                  {
                    widgetName: "Counter Widget",
                    widget: (
                      <CounterWidget
                        type={selectedWidgetType}
                        textColor={selectedTextColor as EColors}
                      />
                    ),
                    widgetType: WidgetType.WIDGET_TYPE_01,
                  },
                  {
                    widgetName: "Gauge Widget",
                    widget: (
                      <GaugeWidget
                        type={selectedWidgetType}
                        textColor={selectedTextColor as EColors}
                      />
                    ),
                    widgetType: WidgetType.WIDGET_TYPE_02,
                  },
                  {
                    widgetName: "Level Widget",
                    widget: (
                      <LevelWidget
                        type={selectedWidgetType}
                        textColor={selectedTextColor as EColors}
                      />
                    ),
                    widgetType: WidgetType.WIDGET_TYPE_03,
                  },
                ]}
                onChange={(type) => {
                  setSelectedWidgetType(type);
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="col-12 col-sm">
              <TextField
                label="Custom Label"
                placeholder="Main Room Inverter"
                name="label"
                register={register("label", {
                  required: "This field is required",
                })}
                errors={errors}
              />
            </Col>
            <Col className="mt-2 mt-sm-0">
              <TextField
                label="Optimal Value"
                type="number"
                placeholder="Optimal Value"
                name="optimalValue"
                register={register("optimalValue", {
                  required: "This field is required",
                })}
                errors={errors}
              />
            </Col>
          </Row>
          {selectedWidgetType !== WidgetType.WIDGET_TYPE_01 && (
            <Row className="mt-3">
              <Col className="col-12 col-sm">
                <TextField
                  label="Minimum Value"
                  type="number"
                  placeholder="Minimum Value"
                  name="minValue"
                  register={register("minValue", {
                    required: "This field is required",
                  })}
                  errors={errors}
                />
              </Col>
              <Col className="mt-2 mt-sm-0">
                <TextField
                  label="Maximum Value"
                  type="number"
                  placeholder="Maximum Value"
                  name="maxValue"
                  register={register("maxValue", {
                    required: "This field is required",
                  })}
                  errors={errors}
                />
              </Col>
            </Row>
          )}
          <Row className="mt-3">
            <Col className="col-12 col-sm">
              <TextField
                label="Step Size"
                placeholder="Step Size"
                errors={Error}
                isRequired={false}
                name="stepSize"
                register={register("stepSize")}
                type="number"
              />
            </Col>
            <Col className="mt-2 mt-sm-0">
              <AppSelect
                label="Viewing Unit"
                selectedValue={selectedViewingUnit}
                options={viewingUnitList}
                onChangeOption={(selectOption) => {
                  setSelectedViewingUnit(selectOption);
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="text-dark font-size-16 font-weight-400">
              Text Color
            </Col>
          </Row>
          <Row className="mt-2 align-items-center">
            <Col className="col-auto">
              <Colors
                colors={[
                  { colorName: EColors.YELLOW },
                  { colorName: EColors.RED },
                  { colorName: EColors.GREEN },
                  { colorName: EColors.BLUE },
                  { colorName: EColors.PURPLE },
                ]}
                onChange={(color) => setSelectedTextColor(color)}
                selectedColor={selectedTextColor as EColors}
              />
            </Col>
            <Col
              className="cursor-pointer"
              onClick={() => setSelectedTextColor(undefined)}
            >
              <Row className="align-items-center">
                <Col className="col-auto pe-0">
                  <MaterialIcon icon="block" size={30} color="#BF4545" />
                </Col>
                <Col className="text-danger ps-2">Clear</Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Row className="justify-content-end mt-4">
          <Col xs="auto">
            <Button
              text="Cancel"
              type="button"
              variant="Outline"
              borderRadius={24}
              padding="12px 80px"
              onClick={props.onCancel}
            />
          </Col>
          <Col xs="auto" className="ps-0">
            <Button
              text={props.selectedWidget?.id ? `Save Widget` : `Add Widget`}
              variant="Primary"
              borderRadius={24}
              padding="12px 80px"
              type="submit"
            />
          </Col>
        </Row>
      </form>
    </>
  );
};

export default AddUpdateWidget;
