import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDeleteWidgetMutation } from "../../../redux/api/widget-api/widget-api";
import WidgetDeleteConfirmModal from "../../../screens/dashboards/components/widget-delete-confirm-modal/widget-delete-confirm-modal";
import iconDropDown from "../../assets/images/arrow_drop_down.svg";
import heat from "../../assets/images/heat-white.svg";
import { Widget } from "../../interfaces/modals";
import Button from "../../ui-elements/button/button";
import IconButton from "../../ui-elements/icon-button/icon-button";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
import ProgressBar from "../../ui-elements/progress-bar/progress-bar";
import { showFailureToast, showSuccessToast } from "../../utils";
import { getReadingTypes } from "../../utils/getReadingTypes";
import AreaChartComponent from "../area-chart/area-chart";
import styles from "./widget-more-info.module.scss";
import { useAppSelector } from "../../../redux/store/store";

interface IProps {
  show: boolean;
  onClose: () => void;
  selectedWidget?: Widget;
  onShowAddUpdateWidget: () => void;
}

const WidgetMoreInfo = (props: IProps) => {
  const [showWidgetDeleteConfirmModal, setShowWidgetDeleteConfirmModal] =
    useState(false);

  const [sensorReadings, setSensorReadings] = useState<number>(0);
  const widgetData = useAppSelector(
    (state) => state.widgetData[props.selectedWidget?.id?.toString() ?? ""]
  );

  const [deleteWidget, { isLoading }] = useDeleteWidgetMutation();

  const onDelete = async () => {
    if (props.selectedWidget?.id) {
      await deleteWidget({ widgetId: props.selectedWidget.id })
        .unwrap()
        .then(() => {
          showSuccessToast("Widget deleted successfully");
          setShowWidgetDeleteConfirmModal(false);
          props.onClose();
        })
        .catch(() => {
          showFailureToast("Failed to delete the widget");
        });
    }
  };

  useEffect(() => {
    if (props.selectedWidget) {
      if (widgetData) {
        setSensorReadings(widgetData.dataSources[0].value);
      }
    }
  }, [widgetData, props.selectedWidget]);

  return (
    <div
      className={styles.widgetMoreInfoContainer}
      style={props.show ? { right: "0" } : { right: "-567px" }}
    >
      <Container fluid>
        <Row className="align-items-center justify-content-between">
          <Col xs="auto">
            <IconButton
              background="#2F2A89"
              icon={heat}
              width={45}
              height={45}
              padding="10px"
              borderRadius={17}
            />
          </Col>
          <Col>
            <div className={styles.title}>Dryer Temperature</div>
          </Col>
          <Col xs="auto">
            <MaterialIcon
              icon="close"
              className="cursor-pointer"
              size={25}
              onClick={() => props.onClose()}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className={styles.text01}>Current Value</Col>
          <Col>
            <Row className="align-items-center justify-content-end">
              <Col xs="auto" className={`pe-0 ${styles.text04}`}>
                Last 24 Hours
              </Col>
              <Col xs="auto" className="ps-1">
                <img src={iconDropDown} alt={""} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-end">
          <Col xs="auto" className={`pe-0 ${styles.text02}`}>
            {sensorReadings}
          </Col>
          <Col xs="auto" className={`ps-2 pb-2 ${styles.text03}`}>
            {getReadingTypes(props.selectedWidget?.dataSources[0]?.viewingUnit)}
          </Col>
        </Row>
        {props.selectedWidget?.dataSources[0].additionalData?.minValue &&
          props.selectedWidget?.dataSources[0].additionalData?.maxValue && (
            <Row className="mt-5">
              <Col>
                <Row className="align-items-center">
                  <Col xs="auto" className="pe-0">
                    <MaterialIcon
                      icon="vertical_align_bottom"
                      size={20}
                      color="#383941"
                    />
                  </Col>
                  <Col xs="auto">
                    <div className={styles.text01}>Minimum Value</div>
                  </Col>
                </Row>
                <Row className="align-items-end">
                  <Col xs="auto" className={`pe-0 ${styles.text02}`}>
                    {
                      props.selectedWidget?.dataSources[0].additionalData
                        ?.minValue
                    }
                  </Col>
                  <Col xs="auto" className={`ps-2 pb-2 ${styles.text03}`}>
                    {getReadingTypes(
                      props.selectedWidget?.dataSources[0].viewingUnit
                    )}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="align-items-center">
                  <Col xs="auto" className="pe-0">
                    <MaterialIcon
                      icon="vertical_align_top"
                      size={20}
                      color="#383941"
                    />
                  </Col>
                  <Col xs="auto">
                    <div className={styles.text01}>Maximum Value</div>
                  </Col>
                </Row>
                <Row className="align-items-end">
                  <Col xs="auto" className={`pe-0 ${styles.text02}`}>
                    {
                      props.selectedWidget?.dataSources[0].additionalData
                        ?.maxValue
                    }
                  </Col>
                  <Col xs="auto" className={`ps-2 pb-2 ${styles.text03}`}>
                    {getReadingTypes(
                      props.selectedWidget?.dataSources[0].viewingUnit
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        <Row className="mt-5">
          <Col className={styles.text01}>Safe Range</Col>
        </Row>
        <Row>
          <Col>
            <ProgressBar />
          </Col>
        </Row>
        <Row className="mt-4 align-items-center">
          <Col xs={"auto"} className={`${styles.text01}`}>
            Alerts
          </Col>
          <Col xs={"auto"} className={`${styles.text05}`}>
            ON
          </Col>
        </Row>
        <Row className="mt-4 align-items-center">
          <Col xs={"auto"} className={`${styles.text01}`}>
            Sensor
          </Col>
          <Col xs={"auto"} className={`${styles.text06}`}>
            {props.selectedWidget?.label}
          </Col>
        </Row>
        <Row className="mt-4 align-items-center">
          <Col>
            <div className={styles.chart}>
              <AreaChartComponent />
            </div>
          </Col>
        </Row>
        <Row className="mt-5 justify-content-end">
          <Col xs="auto">
            <Button
              text="Edit Widget"
              onClick={() => {
                if (props.selectedWidget) {
                  props.onShowAddUpdateWidget();
                }
              }}
              variant="Primary"
              borderRadius={24}
              type="button"
              padding="12px 50px"
            />
          </Col>
          <Col xs="auto">
            <Button
              text="Delete Widget"
              onClick={() => setShowWidgetDeleteConfirmModal(true)}
              variant="Danger"
              borderRadius={24}
              type="button"
              padding="12px 40px"
            />
          </Col>
        </Row>
      </Container>
      <WidgetDeleteConfirmModal
        show={showWidgetDeleteConfirmModal}
        onClose={() => setShowWidgetDeleteConfirmModal(false)}
        onConfirm={onDelete}
        isLoading={isLoading}
      />
    </div>
  );
};

export default WidgetMoreInfo;
