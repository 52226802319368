import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyViewSensorsQuery } from "../../../../redux/api/sensor-api/sensor-api";
import { useLazyViewTelemetryRecordsQuery } from "../../../../redux/api/telemetry-api/telemetry-api";
import {
  useAddTriggerMutation,
  useUpdateTriggerMutation,
} from "../../../../redux/api/trigger-api/trigger-api";
import { useViewContactsQuery } from "../../../../redux/api/user-api/user-api";
import backICon from "../../../../shared/assets/images/arrow-back.svg";
import Recipient from "../../../../shared/components/recipient/recipient";
import {
  ActionType,
  EConditionTypes,
  EContactTypes,
} from "../../../../shared/enums";
import AddTriggerRequestDTO, {
  TriggerRule,
} from "../../../../shared/interfaces/dtos/request-dtos/add-trigger-request-dto";
import {
  ParameterDefinition,
  Sensor,
} from "../../../../shared/interfaces/modals";
import AppSelect, {
  Option,
} from "../../../../shared/ui-elements/app-select/app-select";
import Button from "../../../../shared/ui-elements/button/button";
import CheckBox from "../../../../shared/ui-elements/check-box/check-box";
import IconButton from "../../../../shared/ui-elements/icon-button/icon-button";
import MaterialIcon from "../../../../shared/ui-elements/material-icon/material-icon";
import TextField from "../../../../shared/ui-elements/text-field/text-field";
import { showFailureToast, showSuccessToast } from "../../../../shared/utils";
import { Pages } from "../../../routes";
import styles from "./add-update-triggers.module.scss";

const timeOptions = [
  { label: "Seconds", value: "Seconds" },
  { label: "Minutes", value: "Minutes" },
];

const actionList: Option[] = [
  { value: ActionType.ALERTS, label: "Send Alert" },
];

const conditionList: Option[] = [
  { value: EConditionTypes.EQUALS, label: "Equal" },
  {
    value: EConditionTypes.NOT_EQUALS,
    label: "Not equal",
  },
  { value: EConditionTypes.GREATER_THAN, label: "Greater than" },
  {
    value: EConditionTypes.LESS_THAN,
    label: "Less than",
  },
  {
    value: EConditionTypes.GREATER_THAN_OR_EQUAL,
    label: "Greater than or equal",
  },
  {
    value: EConditionTypes.LESS_THAN_OR_EQUAL,
    label: "Less than or equal",
  },
];

const contactOptions = [
  { label: "Select", value: "" },
  { label: "Email", value: EContactTypes.EMAIL },
  { label: "Mobile Number", value: EContactTypes.MOBILE_NUMBER },
];

interface IRules {
  sensor: Option | undefined;
  parameter: Option | undefined;
  condition: Option | undefined;
  value: number;
}

interface Inputs {
  label: string;
  duration: string;
  rules: IRules[];
  search: string;
}

const defaultFormValues: Inputs = {
  label: "",
  duration: "",
  rules: [],
  search: "",
};

interface ILocationState {
  id: number;
  label: string;
  deviceId: string;
  durationInMinutes: number;
  recipientIds: number[];
  action: string;
  rules: TriggerRule[];
}

const AddUpdateTriggers = () => {
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    control,
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm<Inputs>({ defaultValues: { ...defaultFormValues } });

  const [plcs, setPlcs] = useState<Option[]>([]);
  const [selectedPlc, setSelectedPlc] = useState<Option>(plcs[0]);
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [sensorsOptions, setSensorsOptions] = useState<Option[]>([]);
  const [selectedContactType, setSelectedContactType] = useState<Option>();
  const [noOfRules, setNoOfRules] = useState(1);
  const [selectedTime, setSelectedTime] = useState<Option>(timeOptions[0]);
  const [selectedAction, setSelectedAction] = useState<Option>(actionList[0]);
  const { data: contacts } = useViewContactsQuery({ page: 0, size: 500 });
  const [selectedRecipients, setSelectedRecipients] = useState<number[]>([]);
  const [isAllRecipientsSelected, setIsAllRecipientsSelected] = useState(false);
  const [isRecipientError, setIsRecipientError] = useState(false);
  const [triggerGetSensors] = useLazyViewSensorsQuery();
  const [triggerAddTrigger] = useAddTriggerMutation();
  const [triggerUpdateTrigger] = useUpdateTriggerMutation();
  const [triggerViewTelemetryRecords] = useLazyViewTelemetryRecordsQuery();
  const location = useLocation();
  const locationState: ILocationState = location.state as ILocationState;

  const rules = watch("rules");
  const search = watch("search");
  const { remove } = useFieldArray({ control, name: "rules" });

  useEffect(() => {
    triggerViewTelemetryRecords()
      .unwrap()
      .then((res) => {
        const plcs = Object.keys(res.telemetryRecords).map((key) => {
          return { label: key, value: key };
        });
        setPlcs(plcs);
        setSelectedPlc(plcs[0]);
      })
      .catch(() => {
        setPlcs([]);
      });
  }, [triggerViewTelemetryRecords]);

  useEffect(() => {
    if (
      locationState?.id &&
      sensorsOptions.length > 0 &&
      conditionList.length > 0
    ) {
      setValue("label", locationState.label);
      setSelectedPlc(
        plcs.find((plc) => plc.value === locationState.deviceId) || plcs[0]
      );
      setValue("duration", `${locationState.durationInMinutes}`);
      setSelectedTime(timeOptions[1]);
      setSelectedRecipients(locationState.recipientIds);
      setSelectedAction(
        actionList.find((action) => action.value === locationState.action) ||
          actionList[0]
      );

      locationState.rules.forEach((rule, index) => {
        const sensor = sensorsOptions.find((s) =>
          s.data.parameterDefinitions.find(
            (pd: ParameterDefinition) => pd.id === rule.sensorParamDefId
          )
        );

        const parameter: ParameterDefinition =
          sensor?.data.parameterDefinitions.find(
            (pd: ParameterDefinition) => pd.id === rule.sensorParamDefId
          );

        setValue(`rules.${index}.sensor`, sensor);
        setValue(
          `rules.${index}.parameter`,
          parameter
            ? {
                label: parameter.label,
                value: parameter.label,
                data: parameter,
              }
            : undefined
        );
        setValue(
          `rules.${index}.condition`,
          conditionList.find(
            (condition) => condition.value === rule.condition
          ) || conditionList[0]
        );
        setValue(`rules.${index}.value`, rule.value);
      });
    }
  }, [locationState, sensorsOptions, conditionList[0], setValue]);

  useEffect(() => {
    const allRecipients = contacts
      ? contacts.users
          .map((contact) => {
            const contacts = contact.contacts;

            return contacts.map((c) => c.id);
          })
          .flat()
      : [];

    setIsAllRecipientsSelected(
      allRecipients.every((ar) => selectedRecipients.includes(ar))
    );
  }, [selectedRecipients, contacts]);

  useEffect(() => {
    const sensorsList = sensors
      .filter((sensor) => {
        const parameterDefinitions = sensor.parameterDefinitions;

        return parameterDefinitions.find(
          (pd) => pd.deviceId === selectedPlc?.value
        );
      })
      .map((sl) => {
        return { label: sl.label, value: sl.label, data: sl };
      });

    setSensorsOptions(sensorsList);
  }, [selectedPlc, sensors]);

  useEffect(() => {
    triggerGetSensors({ page: 0, size: 500 })
      .unwrap()
      .then((res) => {
        setSensors(res.sensors);
      })
      .catch(() => {
        setSensors([]);
      });
  }, [triggerGetSensors]);

  const onAddAnotherRule = () => {
    setNoOfRules((ps) => ps + 1);
  };

  const onSelectAllRecipients = (checked: boolean) => {
    const allRecipients = contacts
      ? contacts.users
          .map((contact) => {
            const contacts = contact.contacts;

            return contacts.map((c) => c.id);
          })
          .flat()
      : [];

    if (checked) {
      setSelectedRecipients((ps) => {
        const newContacts = allRecipients.filter((nc) => !ps.includes(nc));

        return [...ps, ...newContacts];
      });

      return;
    }

    setSelectedRecipients((ps) =>
      ps.filter(
        (number) => allRecipients.find((ar) => ar === number) === undefined
      )
    );
  };

  const onSubmit = (data: Inputs) => {
    if (selectedRecipients.length === 0) {
      setIsRecipientError(true);
      return;
    }

    setIsRecipientError(false);

    const duration =
      selectedTime.value === "Seconds" ? +data.duration / 60 : +data.duration;

    const requestData: AddTriggerRequestDTO = {
      id: locationState?.id,
      label: data.label,
      deviceId: selectedPlc.value,
      durationInMinutes: duration,
      additionalFields: {},
      triggerActions: [
        {
          actionType: selectedAction.value as ActionType,
          additionalFields: {
            recipientIds: selectedRecipients,
          },
        },
      ],
      triggerRules: data.rules.map((rule) => {
        return {
          sensorParamDefId: rule.parameter?.data.id || 0,
          condition: rule.condition
            ? (rule.condition?.value as EConditionTypes)
            : EConditionTypes.EQUALS,
          value: +rule.value,
        };
      }),
    };

    (locationState?.id
      ? triggerUpdateTrigger(requestData)
      : triggerAddTrigger(requestData)
    )
      .unwrap()
      .then(() => {
        showSuccessToast(
          locationState?.id
            ? "Trigger updated successfully"
            : "Trigger added successfully"
        );
        navigate(Pages.alerts);
      })
      .catch(() => {
        showFailureToast(
          locationState?.id
            ? "Failed to update trigger"
            : "Failed to add trigger"
        );
      });
  };

  const removeRules = (index: number) => {
    remove(index);
    setNoOfRules((ps) => ps - 1);
  };

  return (
    <div className={`${styles.container} py-3 px-4`}>
      <Row className="align-items-center">
        <Col className="col-auto pe-0">
          <IconButton
            background="#2F2A89"
            icon={backICon}
            width={44}
            height={44}
            padding="12px"
            borderRadius={17}
            onClick={() => navigate(-1)}
          />
        </Col>
        <Col className={styles.txt01}>
          {locationState?.id ? "Update Trigger" : "New Trigger"}
        </Col>
      </Row>
      <Row className="mx-0 gap-4 mt-4">
        <Col className={`${styles.details} p-3`}>
          <TextField
            name="label"
            placeholder="Label"
            label="Label"
            register={register("label", {
              required: "Label is required",
            })}
            errors={errors}
          />
        </Col>
      </Row>
      <Row className="mx-0 gap-4 mt-4">
        <Col className={`col-12 col-md-6 ${styles.details} p-3`}>
          <Row>
            <Col className={styles.txt02}>Data Source</Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <AppSelect
                label="PLC"
                options={plcs}
                selectedValue={selectedPlc}
                onChangeOption={(option) => {
                  setSelectedPlc(option);
                  reset();
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col className={`${styles.details} p-3`}>
          <Row>
            <Col className={styles.txt02}>Action</Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <AppSelect
                label="If rules are met"
                options={actionList}
                selectedValue={selectedAction}
                onChangeOption={(option) => {
                  setSelectedAction(option);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mx-0 gap-4 mt-4">
        <Col className={`col-12 col-md-6 ${styles.details} p-3`}>
          <Row className="align-items-center mb-4">
            <Col className={styles.txt02}>Rules</Col>
            <Col xs="auto">
              <Row className="align-items-center">
                <Col xs="auto">Duration</Col>
                <Col className="ps-1">
                  <TextField
                    placeholder="Duration"
                    type="number"
                    name="duration"
                    register={register("duration", {
                      required: "Duration is required",
                    })}
                    errors={errors}
                  />
                </Col>
                <Col className="ps-0">
                  <AppSelect
                    options={timeOptions}
                    selectedValue={selectedTime}
                    onChangeOption={(option) => {
                      setSelectedTime(option);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {Array.from({ length: noOfRules }).map((rule, index) => {
            return (
              <Row
                key={index}
                className={`${styles.selectedFieldContainer} ${
                  index === 0 ? `mt-2` : `mt-3`
                } mx-0 py-3 px-1`}
              >
                <Col>
                  <Row className="justify-content-end">
                    {noOfRules > 1 && (
                      <Col className="col-auto">
                        <div className="cursor-pointer">
                          <MaterialIcon
                            icon="delete"
                            className="bg-primary text-white rounded p-2"
                            size={16}
                            onClick={() => {
                              removeRules(index);
                            }}
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <AppSelect
                        defaultValue={""}
                        placeholder="Sensor"
                        options={sensorsOptions}
                        label="Sensor"
                        control={control}
                        name={`rules.${index}.sensor`}
                        register={register(`rules.${index}.sensor`, {
                          required: "Please select a sensor",
                        })}
                        errorMessage={
                          errors.rules
                            ? errors.rules[index]?.sensor?.message
                            : ""
                        }
                        menuHeight="150px"
                      />
                    </Col>
                    <Col>
                      <AppSelect
                        defaultValue={""}
                        placeholder="Parameter"
                        options={
                          rules.length > 0 &&
                          rules[index].sensor &&
                          rules[index].sensor.data
                            ? rules[index].sensor.data.parameterDefinitions.map(
                                (pd: ParameterDefinition) => {
                                  return {
                                    label: pd.label,
                                    value: pd.label,
                                    data: pd,
                                  };
                                }
                              )
                            : []
                        }
                        label="Parameter"
                        control={control}
                        name={`rules.${index}.parameter`}
                        register={register(`rules.${index}.parameter`, {
                          required: "Please select a parameter",
                        })}
                        errorMessage={
                          errors.rules
                            ? errors.rules[index]?.parameter?.message
                            : ""
                        }
                        menuHeight="150px"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <AppSelect
                        defaultValue={""}
                        placeholder="Condition"
                        options={conditionList}
                        label="Condition"
                        control={control}
                        name={`rules.${index}.condition`}
                        register={register(`rules.${index}.condition`, {
                          required: "Please select a condition",
                        })}
                        errorMessage={
                          errors.rules
                            ? errors.rules[index]?.condition?.message
                            : ""
                        }
                        menuHeight="150px"
                      />
                    </Col>
                    <Col>
                      <TextField
                        placeholder="Value"
                        type="number"
                        label="Value"
                        register={register(`rules.${index}.value`, {
                          required: "Value is required",
                        })}
                        errors={errors}
                        errorMessage={
                          errors.rules
                            ? errors.rules[index]?.value?.message
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          })}
          <Row className="mt-3 align-items-center justify-content-center">
            <Col xs="auto" className="pe-0">
              <MaterialIcon
                icon="add_circle"
                className="text-primary cursor-pointer"
                size={16}
                onClick={onAddAnotherRule}
              />
            </Col>
            <Col
              xs="auto"
              className={`${styles.text04} cursor-pointer`}
              onClick={onAddAnotherRule}
            >
              Add Another Rule
            </Col>
          </Row>
        </Col>
        <Col className={`${styles.details} p-3`}>
          <Row className="align-items-center justify-content-between">
            <Col xs="auto" className={styles.txt02}>
              Recipients
            </Col>
            <Col className="ms-4">
              <Row className="align-items-center">
                <Col xs="auto">Filter</Col>
                <Col className="ps-1">
                  <AppSelect
                    options={contactOptions}
                    selectedValue={selectedContactType}
                    onChangeOption={(option) => {
                      setSelectedRecipients([]);
                      setSelectedContactType(option);
                    }}
                  />
                </Col>
                <Col className="col-6 ps-0">
                  <TextField
                    placeholder="Search Contacts"
                    register={register("search")}
                    errors={errors}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {isRecipientError && (
            <Row className="my-3">
              <Col xs="auto" className="error">
                Select at least one contact
              </Col>
            </Row>
          )}
          <Row className="my-3">
            <Col xs="auto" className="pe-0">
              <CheckBox
                checked={isAllRecipientsSelected}
                onChange={onSelectAllRecipients}
              />
            </Col>
            <Col className={`${styles.text03} ps-0`}>Select All</Col>
          </Row>
          {contacts &&
            contacts.users
              .filter((user) => user.firstName.includes(search))
              .map((user) => {
                return (
                  <Row key={user.id}>
                    <Col>
                      <Recipient
                        name={user.firstName}
                        contacts={user.contacts}
                        selectedRecipients={selectedRecipients}
                        setSelectedRecipients={setSelectedRecipients}
                        selectedContactType={
                          selectedContactType
                            ? (selectedContactType.value as EContactTypes)
                            : undefined
                        }
                      />
                    </Col>
                  </Row>
                );
              })}
        </Col>
      </Row>
      <Row className="mt-4 justify-content-end">
        <Col xs="auto">
          <Button
            text={locationState?.id ? "Update" : "Save"}
            variant="Primary"
            borderRadius={24}
            padding="12px 80px"
            type="submit"
            onClick={handleSubmit(onSubmit)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AddUpdateTriggers;
