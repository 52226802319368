import { Col, Row } from "react-bootstrap";
import AppModal from "../../ui-elements/app-modal/app-modal";
import Button from "../../ui-elements/button/button";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";

interface IProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const TriggerDeleteModal = (props: IProps) => {
  const { show, onConfirm, onClose } = props;

  return (
    <AppModal show={show} onClose={onClose} width={500}>
      <>
        <Row>
          <Col className="text-dark text-center">Remove Trigger</Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col className="col-auto">
            <div>
              <MaterialIcon
                icon="question_mark"
                color="#fff"
                className="bg-danger p-2 rounded-circle"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-2">
            Are you sure you want to remove this trigger?
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col sm={3}>
            <Button text="Cancel" className="rounded-pill" onClick={onClose} />
          </Col>
          <Col sm={3}>
            <Button
              text="Yes"
              variant="Danger"
              className="rounded-pill"
              onClick={onConfirm}
            />
          </Col>
        </Row>
      </>
    </AppModal>
  );
};

export default TriggerDeleteModal;
