import CreateDashboardRequestDTO from "../../../shared/interfaces/dtos/request-dtos/create-dashboard-request-dto";
import UpdateDashboardRequestDTO from "../../../shared/interfaces/dtos/request-dtos/update-dashboard-request-dto";
import UpdateDashboardResponseDTO from "../../../shared/interfaces/dtos/response-dtos/update-dashboard-response-dto";
import ViewDashboardResponseDTO from "../../../shared/interfaces/dtos/response-dtos/view-dashboard-response-dto";
import { rootApi } from "../api-manager";

// Define a service using a base URL and expected endpoints
export const dashboardAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addDashboard: builder.mutation({
      query: (dashboard: CreateDashboardRequestDTO) => ({
        url: "/dashboard",
        method: "POST",
        body: dashboard,
      }),
      invalidatesTags: ["dashboards"],
    }),
    updateDashboard: builder.mutation<
      UpdateDashboardResponseDTO,
      UpdateDashboardRequestDTO
    >({
      query: (data: UpdateDashboardRequestDTO) => ({
        url: `/dashboard/${data.processId}`,
        method: "PUT",
        body: { label: data.label, additionalFields: data.additionalFields },
      }),
      invalidatesTags: ["dashboards"],
    }),
    viewDashboards: builder.query<ViewDashboardResponseDTO, void>({
      query: () => "/dashboard/all",
      providesTags: ["dashboards"],
    }),
    deleteDashboard: builder.mutation<void, string>({
      query: (dashboardId: string) => ({
        url: `/dashboard/${dashboardId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["dashboards"],
    }),
  }),
});

export const {
  useAddDashboardMutation,
  useUpdateDashboardMutation,
  useViewDashboardsQuery,
  useDeleteDashboardMutation,
} = dashboardAPI;
