import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  useRemoveSensorMutation,
  useViewSensorsQuery,
} from "../../redux/api/sensor-api/sensor-api";
import Pagination from "../../shared/components/pagination/pagination";
import SensorCard from "../../shared/components/sensor-card/sensor-card";
import SensorDeleteModal from "../../shared/components/sensor-delete-modal/sensor-delete-modal";
import Tabs from "../../shared/components/tabs/tabs";
import { Sensor } from "../../shared/interfaces/modals";
import Button from "../../shared/ui-elements/button/button";
import { showFailureToast, showSuccessToast } from "../../shared/utils";
import { Pages } from "../routes";
import styles from "./manage-sensors.module.scss";

const ITEMS_PER_PAGE = 10;

const ManageSensors = () => {
  const navigate = useNavigate();
  const [isShowSensorDeleteModal, setIsShowSensorDeleteModal] = useState(false);
  const [sensorDeleteId, setSensorDeleteId] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const { data: sensorsData } = useViewSensorsQuery({
    page: currentPage,
    size: ITEMS_PER_PAGE,
  });
  const [removeSensor] = useRemoveSensorMutation();
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [sensorsLength, setSensorsLength] = useState(0);

  useEffect(() => {
    if (sensorsData) {
      setSensors(sensorsData.sensors);
      setSensorsLength(sensorsData.totalElements);
      return;
    }

    setSensors([]);
    setSensorsLength(0);
  }, [sensorsData]);

  const onSensorEdit = (sensor: Sensor) => {
    navigate(Pages.updateSensor, {
      state: {
        sensorId: sensor.id,
        deviceId: sensor.parameterDefinitions[0].deviceId,
        sensorName: sensor.label,
        modelNumber: sensor.modelNumber,
        parameterDefinitions: sensor.parameterDefinitions,
      },
    });
  };

  const onShowSensorDeleteModal = (sensorId: number) => {
    setSensorDeleteId(sensorId);
    setIsShowSensorDeleteModal(true);
  };

  const onSensorDelete = () => {
    removeSensor({ sensorId: sensorDeleteId })
      .unwrap()
      .then(() => {
        showSuccessToast("Sensor deleted successfully");
      })
      .catch(() => {
        showFailureToast("Sensor deletion is unsuccessful");
      })
      .finally(() => {
        setIsShowSensorDeleteModal(false);
      });
  };

  return (
    <>
      <Row>
        <Col>
          <Tabs />
        </Col>
      </Row>
      <Row className="mx-2">
        <Col className={`${styles.container} p-4 mt-4`}>
          <Row className="align-items-center">
            <Col className={styles.title}>Manage Sensors</Col>
            <Col xs="auto">
              <Button
                text="Add New Sensor"
                onClick={() => {
                  navigate(Pages.addSensor);
                }}
                variant="Primary"
                borderRadius={24}
                padding="8px 30px"
                type="button"
              />
            </Col>
          </Row>
          <Row className="mt-2 g-3">
            {sensors?.map((s) => {
              return (
                <Col lg={6} key={uuid()}>
                  <SensorCard
                    sensor={s}
                    onEdit={onSensorEdit}
                    onDelete={onShowSensorDeleteModal}
                  />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          {" "}
          <Pagination
            length={sensorsLength}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage + 1}
            updateCurrentPage={(pageNumber: number) => {
              setCurrentPage(pageNumber - 1);
            }}
          />
        </Col>
      </Row>
      <SensorDeleteModal
        show={isShowSensorDeleteModal}
        onClose={() => {
          setIsShowSensorDeleteModal(false);
        }}
        onConfirm={onSensorDelete}
      />
    </>
  );
};

export default ManageSensors;
